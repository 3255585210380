import { WhlPageType } from '../models/WhlPageType';

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return;

  let fromPageType = null;
  let toPageType = null;

  const fromPathSegments = from.path.split('/');
  const toPathSegments = to.path.split('/');

  // Check from-path
  if (fromPathSegments.includes(WhlPageType.Bookmarks)) {
    fromPageType = WhlPageType.Bookmarks;
  } else if (fromPathSegments.includes(WhlPageType.Overview)) {
    fromPageType = WhlPageType.Overview;
  } else if (fromPathSegments.includes(WhlPageType.List)) {
    fromPageType = WhlPageType.List;
  } else if (fromPathSegments.includes(WhlPageType.Detail)) {
    fromPageType = WhlPageType.Detail;
  }

  // Check to-path
  if (toPathSegments.includes(WhlPageType.Bookmarks)) {
    toPageType = WhlPageType.Bookmarks;
  } else if (toPathSegments.includes(WhlPageType.Overview)) {
    toPageType = WhlPageType.Overview;
  } else if (toPathSegments.includes(WhlPageType.List)) {
    toPageType = WhlPageType.List;
  } else if (toPathSegments.includes(WhlPageType.Detail)) {
    toPageType = WhlPageType.Detail;
  }

  if (fromPageType && import.meta.client) {
    sessionStorage.setItem('previousWhlPageType', fromPageType);
  }
  if (toPageType && import.meta.client) {
    sessionStorage.setItem('currentWhlPageType', toPageType);
  }
});
